

<template>
   <div :class="{loader: isloading}">
    <div class="cell small-12 medium-10" style="padding:2px" >
       
            <template v-if="isloading==false">
            <!-- unique by competition -->
                <div v-for="(row) in unique(eventsbycompetition.BO,'compdesc', 'ortpo', '1')" :key="row.bid" >
                
                <!-- is there a competition level promo? -->
                    <div>
                        <bannercompetitions :CompID="row.cnid" />
                    </div>

                    <div class="grid-x "  >
                        <h1 class="cell qmajorheader qalignleft">{{row.compdesc}}</h1>
                    </div>   
        
                    <div v-for="(row2,a) in eventsbycompetition.BO" :key="a" >
                  
                        <template v-if="row2.compdesc == row.compdesc">
                    
                    <div class="grid-x qsmallfont qhighlight" style="line-height:1.5rem" :key="row2.t" v-if="a==0">
                   
                        <div class="cell small-8 medium-6  qalignleft qmediumfontlite">{{$todayTomorrow(row2.t,$dateformattype.daymonth) }} @ {{$todayTomorrow(row2.t,$dateformattype.timeonly) }}</div>
                        <div class="cell small-1 text-center">{{ langConv(eventsbycompetition.h.head1) }}</div>
                        <div class="cell small-1 text-center" >{{ langConv(eventsbycompetition.h.head2) }}</div>
                        <div class="cell small-1 text-center" >{{ langConv(eventsbycompetition.h.head3) }}</div>
                        <div class="cell medium-2 show-for-medium "></div>
                        
                    </div>
                    <div v-else>  
                         
                         <div class="grid-x qsmallfont qhighlight" style="line-height:1.5rem;margin-top:2px" :key="row2.t" v-if="eventsbycompetition.BO[a-1].t!=row2.t">
                   
                            <div class="cell small-8 medium-6  qalignleft qmediumfontlite">{{$todayTomorrow(row2.t,$dateformattype.daymonth) }} @ {{$todayTomorrow(row2.t,$dateformattype.timeonly) }}</div>
                            <div class="cell small-1 text-center">{{ langConv(eventsbycompetition.h.head1) }}</div>
                            <div class="cell small-1 text-center" >{{ langConv(eventsbycompetition.h.head2) }}</div>
                            <div class="cell small-1 text-center" >{{ langConv(eventsbycompetition.h.head3) }}</div>
                            <div class="cell medium-2 show-for-medium "></div>
                        </div> 
                        
                    </div>

                        <div class="grid-x text-center" style="line-height:2rem" :key="row2.bid">
                         
                                <div class="cell small-8 medium-6 text-left"  >
                                       
                                        <router-link class="qborderlink qsmallfontplus1"  style="margin-left:10px" :to="'/Event/' + eventsbycompetition.h.sport.replace(/\s+/g, '-') + '/' + row2.country.replace(/\s+/g, '-') + '/' + row2.compdesc.replace(/\s+/g, '-') + '/' + row2.team1.replace(/\s+/g, '-') + '-v-' + row2.team2.replace(/\s+/g, '-') + '/' + row2.urldesc + '/' + row2.bid ">
                                            {{row2.team1}}
                                        <!-- </router-link> -->
                                        <star  :cmpid="row2.t1id" :cmpdesc="row2.team1"></star>
                                   
                                    <span>&nbsp;v&nbsp;</span>

                                    <!-- <router-link class="qborderlink qsmallfontplus2" :to="'/Event/' + eventsbycompetition.h.sport.replace(/\s+/g, '-') + '/' + row2.country.replace(/\s+/g, '-') + '/' + row2.compdesc.replace(/\s+/g, '-') + '/' + row2.team1.replace(/\s+/g, '-') + '-v-' + row2.team2.replace(/\s+/g, '-') + '/' + row2.urldesc + '/' + row2.bid ">-->
                                    <span class="qborderlink qsmallfontplus1" >{{row2.team2}}</span>
                                    <span>&nbsp;</span>
                                        <star :cmpid="row2.t2id" :cmpdesc="row2.team2"></star>
                                    </router-link>
                                    <!-- <br />
                                    <small v-if="row2.t2pos!=null">LP: {{row2.t2pos}}</small> -->
                                    <!-- <template v-for="(row4,i4) in row2.t2res">
                                        <span :key="i4" :class="row4.res" style="text-align:center" :title="row4.t1 + ' v ' + row4.t2 + ' ' + row4.t1score + ' - ' + row4.t2score"></span>
                                    </template> -->
                           
                                    <span v-if="row2.stat"><img class="qpointer" style="height:20px;width:20px"  v-on:click="showstats(row2.bid)" :src="require('/public/assets/images/statistics.png')" alt="statistics" :title="langConv('Previous Results')"/></span>
                                
                                        
                                </div>


                                <div class="cell small-1 show-for-small-only bestoddssmall"  >{{$formatOddsTS(row2.odd1)}}</div>
                                <div class="cell small-1 show-for-small-only bestoddssmall"  v-if="eventsbycompetition.h.head2!=''">{{$formatOddsTS(row2.odd2)}}</div>
                               
                                <div class="cell small-1 show-for-small-only  bestoddssmall" >{{$formatOddsTS(row2.odd3)}}</div> 
         
                                <div class="cell medium-1 show-for-medium bestodds" style="border:2px solid white">{{$formatOddsTS(row2.odd1)}}</div>
                                <div class="cell medium-1 show-for-medium bestodds" style="border:2px solid white" v-if="eventsbycompetition.h.head2!=''">{{$formatOddsTS(row2.odd2)}}</div>
                              
                                <div class="cell medium-1 show-for-medium  bestodds"  style="border:2px solid white">{{$formatOddsTS(row2.odd3)}}</div> 
                              
                                 <div class="cell shrink  hide-for-small-only" style="padding-left:15px">
                                    <actionbutton :linkbutton="true" buttontext="More" :routerlinkpath="'/Event/' + eventsbycompetition.h.sport.replace(/\s+/g, '-') + '/' + row2.country.replace(/\s+/g, '-') + '/' + row2.compdesc.replace(/\s+/g, '-') + '/' + row2.team1.replace(/\s+/g, '-') + '-v-' + row2.team2.replace(/\s+/g, '-') + '/' + row2.urldesc + '/' + row2.bid"></actionbutton> 
                                 </div> 
                                 <div class="cell shrink  show-for-small-only" style="padding-left:15px">
                                    <actionbutton :linkbutton="true" buttontext="" :routerlinkpath="'/Event/' + eventsbycompetition.h.sport.replace(/\s+/g, '-') + '/' + row2.country.replace(/\s+/g, '-') + '/' + row2.compdesc.replace(/\s+/g, '-') + '/' + row2.team1.replace(/\s+/g, '-') + '-v-' + row2.team2.replace(/\s+/g, '-') + '/' + row2.urldesc + '/' + row2.bid"></actionbutton> 
                                 </div>  
                             
                        </div>
                        <div v-if="openstat==row2.bid">
                                    
                                <resultstable :eventid=row2.bid></resultstable>
                    
                        </div>
                    
                        </template>
                    </div>
                
                </div>


            <div v-for="row in unique(eventsbycompetition.BO,'compdesc', 'ortpo', '0')" :key="row.guid">
                <div class="qmajorheader qalignleft" >
                   {{row.compdesc}} - {{row.betdesc}}
                </div>   
                <div class="grid-x qsubheader qalignleft">
                    <div class="cell small-7 text-center">Competitor</div>
                    <div class="cell small-2 text-center" >{{eventsbycompetition.h.outhead1 }}</div>
                    <div class="cell auto text-center" ></div>
                    <div class="cell shrink text-center" >
                        <actionbutton buttontext="All Odds" :routerlinkpath="'/Outright/' + eventsbycompetition.h.sport.replace(/\s+/g, '-') + '/' + row.country.replace(/\s+/g, '-') + '/' + row.compdesc.replace(/\s+/g, '-') + '/' + row.urldesc + '/' + row.bid "></actionbutton>
                    </div>
                </div>

                <div class="grid-x text-center" style="padding:5px">
                    <template v-for="(row3) in eventsbycompetition.BO">
                    <template v-if="row3.compdesc == row.compdesc && row3.ortpo ==0" >
                        <div class="cell small-7 text-center" :key="row3.guid"><router-link class="qborderlink qmediumfont" :to="'/Outright/' + eventsbycompetition.h.sport.replace(/\s+/g, '-') + '/' + row3.country.replace(/\s+/g, '-') + '/' + row3.compdesc.replace(/\s+/g, '-') + '/' + row3.urldesc + '/' + row3.bid ">{{row3.team1}}</router-link></div>
                        <div class="cell small-2 bestodds" :key="row3.guid" >{{$formatOddsTS(row3.odd1)}}</div>
                        <div class="cell small-3" :key="row3.guid"></div>
                    </template>
                    </template>
                </div> 
        
            </div>
        </template>
       
    </div>
  </div>

</template>

<script>
 
   // adapted from https://codepen.io/frogmcw/pen/deqRwa after failing to get the foundation accordian and Vue to work nicely
    import axios from 'axios';
    import star from '/src/components/components/star.vue'
    import bannercompetitions from '/src/components/adverts/bannercompetitions.vue'
    import Actionbutton from '../components/components/actionbutton.vue';
    import resultstable from '../components/components/results.vue';
    
    export default {

        name: "events",
        data: function () {
            return {
                eventsbycompetition: [],
                isloading: true,
                lastdate: '',
                openstat: 0,
                resultsloaded: false
            };
        },
        components: {
            star,
            bannercompetitions,
            resultstable,
            Actionbutton
        },

        props: {
       
            eventparams: String,
        
        },

        mounted() {
            this.eventsbycompetitionTable();
            
        },

        methods: {

            showstats(val) {
                
              //  this.resultsTable(val);
                if (this.openstat == val) {
                    this.openstat = 0;
                } else {
                    this.openstat = val;
                }

            },


            storelastdate: function(val) {
                this.lastdate = val;

            },

            //get data for odds table
            eventsbycompetitionTable: function () {

                   axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/EventsbyCompetition", {

                    params: {
                        noitems: 10,
                        eventparams: this.eventparams
                    }

                }).then(response => {

                    this.eventsbycompetition = JSON.parse(response.data)[0];

                   // console.log(this.eventsbycompetition);

                    //have to change title here because dont know about competition in routre.beforePush event 
                    document.title = this.eventsbycompetition.BO[0].compdesc + ' Events';   
                    this.isloading  = false;
                 
                 

                }).catch(error => {
                   
                    console.log(error)
                });
            },

        }

    }
</script>

<style scoped>





</style>




